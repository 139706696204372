import React from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
const Image = loadable(() => import('./Image'));
const Content = loadable(() => import('./Content'));
import Fade from 'react-reveal/Fade';

import './PageHeader.scss';

const PageHeader = ({
                        title,
                        subtitle,
                        button,
                        backgroundImage,
                        large,
                        className = ''
                    }) => {
    if (large) className += ' PageHeader-large';
    return (
        <div className={`PageHeader relative ${className}`}>
            {backgroundImage && (
                <Image
                    background
                    resolutions="large"
                    src={backgroundImage}
                    alt={title}
                    size="cover"
                    lazy={true}
                />
            )}
            <div className="background-layer"></div>
            <Fade>
            <div className="container relative"
                 >
                <h1 className="PageHeader--Title">{title}</h1>
                {subtitle && (
                    <Content className="PageHeader--Subtitle" src={subtitle}/>
                )}
                {button && (
                    <a href="tel:0561510561"><button className='Button'>
                        {button}
                    </button></a>
                )}

            </div>
            </Fade>
        </div>
    )
};

PageHeader.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    button: PropTypes.string,
    carousel: PropTypes.any,
    backgroundImage: PropTypes.any
};

export default PageHeader


